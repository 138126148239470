<template>
  <div
    :class="[
      { expanded: true },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    class="main-menu menu-shadow shadow h-100 overflow-hidden"
    style="background: transparent;"
  >
    <!-- 背景图 -->
    <!-- <div :style="'background: url(' + sideImg + ') center center / cover;'" class="w-100 h-100 position-absolute" style="filter: blur(0px); z-index: -1"></div> -->
    <!--		<div v-if="project_id!=72" :style="'background: url(' + sideImg + ') center center / cover;'" class="w-100 h-100 position-absolute" style="filter: blur(0px); z-index: -1"></div>-->
    <!--		<div v-else :style="'background: url(' + sideImg + ') left center / cover;'" class="w-100 h-100 position-absolute" style="filter: blur(0px); z-index: -1"></div>-->
    <!-- main menu header-->
    <div
      v-if="project_id === 74"
      class="navbar-header expanded mt-2 pt-1"
      style="height: 4rem; display: none"
    >
      <slot
        :collapseTogglerIcon="collapseTogglerIcon"
        :toggleCollapsed="toggleCollapsed"
        name="header"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <!--					<li class="nav-item mr-auto">-->
          <!--						<b-link class="navbar-brand mt-0 w-100" to="/">-->
          <!--							<span class="brand-logo">-->
          <!--								<b-img alt="logo" src="@/assets/images/logo/cabr-logo.png" style="margin-left: calc(50% - 0rem)" />-->
          <!--							</span>-->
          <!--							<h2 class="brand-text font-title">-->
          <!--								{{ $t("Brand Name") }}-->
          <!--							</h2>-->
          <!--						</b-link>-->
          <!--          </li>-->
          <li class="nav-item w-100">
            <b-link class="navbar-brand mt-0 w-100" to="/">
              <!--							<span class="brand-logo">-->
              <!--								<b-img alt="logo" src="@/assets/images/logo/cabr-logo.png" style="margin-left: calc(50% - 0rem)" />-->
              <!--							</span>-->
              <!--							<h2 class="brand-text font-title">-->
              <!--								{{ $t("Brand Name") }}-->
              <!--							</h2>-->
              <b-img
                alt="logo"
                src="/images/cabr-logo.png"
                style="
                  height: 3rem;
                  width: 3.5rem;
                  position: relative;
                  top: -1rem;
                  left: calc(50% - 2rem);
                "
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>

    <div class="text-center my-1">
      <b-button
        :style="{ background: 'transparent' }"
        style="width: calc(100% - 30px)"
        to="/project-list"
        variant="outline-primary"
      >
        <feather-icon class="mr-50" icon="MapIcon" />
        <span class="align-middle">项目地图</span>
      </b-button>
    </div>

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="{ maxScrollbarLength: 60, wheelPropagation: true }"
      class="main-menu-content scroll-area overflow-hidden"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="menuTree"
        class="navigation navigation-main"
        style="background: transparent"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import store from "@/store";
import { computed, provide, reactive, ref, toRefs } from "@vue/composition-api";
import { $themeConfig } from "@themeConfig";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VerticalNavMenuItems from "@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu";
import useAppConfig from "@core/app-config/useAppConfig";
import Ripple from "vue-ripple-directive";
import { isNavLinkActive } from "@core/layouts/utils";
import { routerParams } from "@/libs/utils/routerParams";

export default {
  name: "VerticalMenu",
  directives: {
    Ripple,
  },
  props: ["menuTree"],
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
  },
  setup(props) {
    /**
     * 优先读取store中的菜单列表
     * 张强 2021-08-09
     */
    const event = reactive({
      show: false,
      project_id: computed(() => {
        return parseInt(routerParams("project_id"));
      }),
      sideImg: computed(() => {
        let img;
        if (store.state.appConfig.layout.skin === "dark") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          if (event.project_id !== 72)
            img = require("@/assets/images/bg/bg_4.jpg");
          else img = require("@/assets/images/bg/bg_4_1.jpg");
        } else {
          img = require("@/assets/images/bg/bg_3.jpg");
        }
        return img;
      }),
    });
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      ...toRefs(event),
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      isNavLinkActive,
      updateMouseHovered,
      collapseTogglerIconFeather,
      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
//@import "src/assets/scss/project-jsf/jsf";
//#app-sidebar-menu.b-sidebar {
//	width: 50% !important;
//	min-width: 900px;
//}

.dark-layout .bg-light-secondary {
  color: #b4b7bd !important;
}

.btn-flat-primary:hover,
.btn-flat-primary:active,
.btn-flat-primary.active,
.btn-flat-primary:focus {
  color: #1890ff !important;
}

.dark-layout
  .main-menu-content
  .navigation-main
  .sidebar-group-active
  .menu-content,
.dark-layout .main-menu-content .navigation-main .nav-item .menu-content {
  background-color: transparent !important;
}

//.border-box-color {
//  &:focus,
//  &:hover {
//    background: transparent;
//    @include hover-text("text-color-1");
//  }
//}
</style>
